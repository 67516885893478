import React from 'react'
import {Link} from 'react-router-dom'
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import translation from '../../translation/translate';
import {toLocaleDateString} from '../../shared/utils/utils';
import style from '../../assets/css/style';

interface Props {
    operation: any;
}

interface State {
    user: any;
}

class OperationCardItem extends React.Component <Props, State> {

        render() {
        const {operation} = this.props;
            const btn_participation = <Button style={style.btnPart as any} size='small' component={Link} to={{
                pathname: `/nouvelle-demande/${operation.id}`,
                state: operation['@type']
            }}>{translation[process.env.REACT_APP_CLIENT as any].home_new}</Button>



        let dateDay = new Date();

        return (
            <div>
                <Card style={{marginRight: '10px', marginTop:"8%", textAlign: 'center'}}>
                    <CardContent>
                        {operation['@type'] != 'CoopOperation' &&
                        <img style={{width: '335px', height: '200px', borderRadius: '2%'}}
                             src={operation.image && operation.image.contentUrl && `${process.env.REACT_APP_BACKEND_URL}/media/${operation.image.contentUrl}`}
                             alt="operation"/>
                        }
                        {operation['@type'] === 'CoopOperation' &&
                        <img style={{width: '335px', height: '200px', borderRadius: '2%'}}
                             src={`${process.env.REACT_APP_BACKEND_URL}/media/${process.env.REACT_APP_CLIENT}_coop.jpg`}
                             alt="operation"/>
                        }
                    </CardContent>
                    <CardContent>
                        <h4 style={style.titleOP as any}>
                            {operation.title}
                        </h4>
                        <p style={style.cardCategory as any}>
                            {operation.description}
                        </p>
                        <div >
                            Opération du <span
                            style={style.spanColor}>{toLocaleDateString(operation.startDate)} </span> au <span
                            style={style.spanColor}> {toLocaleDateString(operation.endDate)}</span>
                        </div>
                        <div >
                            Participation possible jusqu'au <span
                            style={style.spanColor}> {toLocaleDateString(operation.closingDate)}</span>
                        </div>
                    </CardContent>
                    <CardActions style={{
                        margin: 'auto',
                        display: 'block'
                    }}>

                        <div style={{paddingBottom: '25px'}}>
                            {operation.operationCustomers.length > 0 && operation.operationCustomers[0].counter < operation.operationCustomers[0].partMax && new Date(operation.closingDate) > dateDay  && new Date(operation.openingDate) < dateDay &&
                                btn_participation
                            }
                            {operation.operationCustomers.length === 0 && new Date(operation.closingDate) > dateDay && new Date(operation.openingDate) < dateDay &&
                                btn_participation
                            }
                            {operation.operationCustomers.length === 0 && new Date(operation.openingDate) > dateDay &&
                            <Button style={style.btnPartFinish as any} size='small'>Opération en cours</Button>
                            }
                            {operation.operationCustomers.length > 0 && operation.operationCustomers[0].counter >= operation.operationCustomers[0].partMax && operation.operationCustomers[0].partMax > 0 &&
                            <Button style={style.btnPartFinish as any} size='small'>Participation effectuée</Button>
                            }
                            {operation.operationCustomers.length > 0 && operation.operationCustomers[0].counter < operation.operationCustomers[0].partMax && new Date(operation.closingDate) > dateDay  && new Date(operation.openingDate) > dateDay &&
                            <Button style={style.btnPartFinish as any} size='small'>Opération en cours</Button>
                            }
                            {operation.operationCustomers.length > 0 && new Date(operation.startDate) > dateDay &&
                            <Button style={style.btnPartFinish as any} size='small'>Opération à venir</Button>
                            }
                            {new Date(operation.closingDate) < dateDay &&
                                <Button style={style.btnPartFinish as any} size='small'>Opération terminée</Button>
                            }
                            {null != this.props.operation.contract.contentUrl &&
                            <Button
                                href={`${process.env.REACT_APP_BACKEND_URL}/media/${operation.contract.contentUrl}`}
                                type={'button'}
                                rel='noreferrer noopener'
                                target='_blank'
                                style={style.conditionGeneral as any}>{operation['@type'] === "ChallengeOperation" ? translation[process.env.REACT_APP_CLIENT as any].operation_condition_challenge : translation[process.env.REACT_APP_CLIENT as any].operation_condition}
                            </Button>
                            }
                            {null === this.props.operation.contract.contentUrl &&
                            <Button
                                href={`${process.env.REACT_APP_BACKEND_URL}/media/${operation.contract.contentUrl}`}
                                type={'button'}
                                rel='noreferrer noopener'
                                target='_blank'
                                style={style.conditionGeneralNone as any}>{translation[process.env.REACT_APP_CLIENT as any].operation_condition}
                            </Button>
                            }
                        </div>
                    </CardActions>
                </Card>
            </div>
        )
    }
}

export default OperationCardItem
