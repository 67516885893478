import React from "react"
import _ from 'lodash';
import api from "../../api"
import UploadFormParticipationComponent from "./upload-form-participation.component";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {toast} from 'react-toastify';
import {FieldGroup, FormBuilder, Validators} from "react-reactive-form";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import CircularProgress from "@mui/material/CircularProgress";
import style from '../../assets/css/style';
import ReactiveFormInputText from "../../shared/form/reactive-form-input-text.component";

interface Props {
    data: any
    history: any,
    match_params: any,
    comments:any,
}

interface State {
    filterOperationLabel:any
    requireOperation:any,
    gridNumber:any,
    type:any
}

class RequestForm extends React.Component <Props, State> {
    state = {
        filterOperationLabel: [],
        requireOperation: [],
        gridNumber: '',
        type:''
    };

    requestForm = FormBuilder.group({
        declaredQuantity: ["", Validators.pattern('^[0-9]*$')],
    });

    componentDidMount() {
        const data = this.props.data;

        if (process.env.REACT_APP_TEMPLATE_QUANTITY === "true") {
            this.requestForm.controls['declaredQuantity'].setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
            this.requestForm.controls['declaredQuantity'].updateValueAndValidity();
        }

        this.getHostSettings();

        if (undefined !== data && data.operation && data.operation.needIban && data.user.cachedIban === '') {
            this.props.history.push('/mon-profil')
            toast.error('Veuillez renseigner vos coordonnées bancaires avant de participer à cette offre.')
        }
    }

    getHostSettings() {
        api.operations.operation_label()
            .then(resp => {
                let labelType: any;
                let location = this.props.history.location.state.type ? this.props.history.location.state.type : this.props.history.location.state;

                switch (location) {
                    case 'Operation':
                        labelType = "classic";
                        break;
                    case 'LocalOperation':
                        labelType = "local";
                        break;
                    case 'CoopOperation':
                        labelType = "cooperation";
                        break;
                    case 'ChallengeOperation':
                        labelType = "challenge";
                        break;
                    case 'VisibilityOperation':
                        labelType = "visibility";
                        break;
                    default:
                        break;
                }

                const filterOperationLabel = _.filter(resp.data['hydra:member'], {
                    operationType: labelType,
                    isActive: true
                });
                const requireOperation = filterOperationLabel.map((required) => ({
                    isRequired: required.isRequired,
                    labelType: labelType
                }));

                let gridNumber;
                switch (requireOperation.length) {
                    case 1:
                        gridNumber = 12;
                        break;
                    case 2:
                        gridNumber = 6;
                        break;
                    case 3:
                        if (window.screen.width < 1200) {
                            gridNumber = 12;
                        } else {
                            gridNumber = 4;
                        }
                        break;
                    case 4:
                        gridNumber = 3;
                        break;
                    default:
                }

                this.setState({
                    filterOperationLabel: filterOperationLabel,
                    requireOperation: requireOperation,
                    gridNumber: gridNumber,
                    type: labelType
                })
                this.requestForm.updateValueAndValidity();
            })
            .catch(error => {
                console.error(error);
            })
    }

    handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        let arrayValidatorLength: any = [];
        this.state.filterOperationLabel.forEach((number: any) => {
            if (number.isRequired) {
                if (number) {
                    const isBelowThreshold = (currentValue: number) => currentValue > 0;
                    const array1 = [this.requestForm.get([number.labelType]).value.length];
                    arrayValidatorLength.push(array1.every(isBelowThreshold))
                }
            }
        });

        if (!arrayValidatorLength.includes(false)) {
            let objectData : any = {
                participation: '/api/operations/' + this.props.match_params.ope_id,
                type: this.props.history.location.state,
                urlImage: this.requestForm.value,
            }
            if (this.props.history && this.props.history.location.state && this.props.history.location.state.edited) {
                objectData.operation = this.props.history.location.state.data;
                objectData.edited = this.props.data.old_part.id;
            } else {
                objectData.operation = this.props.data.operation;
            }
            this.props.history.push({
                pathname: `/recap-participation/${this.props.match_params.ope_id}`,
                state: objectData
            })
        } else {
            toast.error("Vous n'avez pas téléchargé tous les fichiers")
        }
    };

    renderBtn(invalid: boolean | undefined) {
        let notBlock = null;
        let block = null;
        if (invalid) {
            notBlock = (
                <Button
                    style={style.btnOpacity as any}
                    disabled={invalid}
                    type="submit">Valider ma participation
                </Button>
            )
        } else {
            block = (
                <Button
                    style={style.notBtnOpacity as any}
                    type="submit">Valider ma participation
                </Button>
            )
        }
        return (
            <div style={{float: 'right', margin: '10px'}}>
                {notBlock}
                {block}
            </div>
        )
    }

    render() {
        const {filterOperationLabel} : any = this.state
        return (
            <Grid container justifyContent='center'
                  style={{position: 'relative', minHeight: 'calc(100vh - 153px)', paddingTop: '85px'}}>
                <Grid xs={12}>
                    <FieldGroup
                        control={this.requestForm}
                        render={({invalid}) => (
                            <form onSubmit={this.handleSubmit}>
                                {this.state.filterOperationLabel.length <= 0 &&
                                <Grid container spacing={2}>
                                    <CircularProgress style={{margin: 'auto'}}/>
                                </Grid>
                                }
                                {this.state.filterOperationLabel.length > 0 &&
                                <Card>
                                    <CardContent>
                                        {this.props.history && this.props.history.location.state && this.props.history.location.state.edit &&
                                        <h3 style={style.opTitle as any}>Opération
                                            : {this.props.history.location.state.data.title}</h3>
                                        }
                                        {this.props.data.operation &&
                                        <h3 style={style.opTitle as any}>Opération
                                            : {this.props.data.operation.title}</h3>
                                        }
                                        <Grid container spacing={3}>
                                            {filterOperationLabel.map((item: any) =>
                                                <Grid item xs={this.state.gridNumber as any}>
                                                    <Paper style={style.paperUpload} elevation={0}>
                                                        <h5 style={style.labelOperationGeneric as any}>{item.labelName}</h5>
                                                        <UploadFormParticipationComponent title={item.labelType}
                                                                                          label={item.labelName}
                                                                                          type={item.labelType}
                                                                                          required={item.isRequired}
                                                                                          comments={this.props.comments} />
                                                        <p style={style.spanOperationGeneric as any}>(6 Mo maximum, formats
                                                            jpg/png/pdf
                                                            autorisés)</p>
                                                    </Paper>
                                                </Grid>
                                            )}
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={5} style={{marginTop:'2%'}}>
                                                {process.env.REACT_APP_TEMPLATE_QUANTITY === "true" && filterOperationLabel[0].operationType !== "visibility" && filterOperationLabel[0].operationType !== "local" &&
                                                <ReactiveFormInputText variant="outlined" inputLabel={'Quantité déclarée *'}
                                                                       inputName={"declaredQuantity"} type={'number'}/>
                                                }
                                            </Grid>
                                        </Grid>
                                        {this.renderBtn(invalid)}
                                    </CardContent>
                                </Card>
                                }
                            </form>
                        )}/>
                </Grid>
            </Grid>
        )
    }
}

export default RequestForm
