import React from 'react'
import Email from '@mui/icons-material/Email'
import LockOpen from '@mui/icons-material/LockOpen'
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import RequestPwdForm from '../password/request-password-form.component';
import translation from '../../../translation/translate'
import Button from '@mui/material/Button';
import ReactiveFormInputText from '../../../shared/form/reactive-form-input-text.component'
import {FormBuilder, FieldGroup, Validators,} from "react-reactive-form";
import style from '../../../assets/css/style';
import {LOGIN_FORM} from '../../../shared/utils/variable'
import Grid from "@mui/material/Grid";

interface Props {
    classes:any;
    login:any;
}

class LoginForm extends React.Component <Props>{
  loginForm = FormBuilder.group({
      [LOGIN_FORM.USERNAME]: ["", Validators.required],
      [LOGIN_FORM.PASSWORD]: ["", Validators.required],
    });

  handleSubmit=(e: { preventDefault: () => void; }) => {
        e.preventDefault();
        this.props.login(this.loginForm.value)
  };

  render() {
    const {classes} = this.props;
    return (
        <div>
            <FieldGroup
                control={this.loginForm}
                render={() => (
                    <form onSubmit={this.handleSubmit}>
                        <CardContent>
                            <h4 style={style.cardTitle as any} >{translation[process.env.REACT_APP_CLIENT as string].login_title}</h4>
                        </CardContent>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} sx={{mb:'18px'}}>
                                    <ReactiveFormInputText inputLabel={'Adresse email *'} inputName={LOGIN_FORM.USERNAME} inputIcon={<Email/>} type={'text'}/>
                                </Grid>
                                <Grid item xs={12} >
                                    <ReactiveFormInputText inputLabel={'Mot de passe *'} inputName={LOGIN_FORM.PASSWORD} inputIcon={<LockOpen/>} type={'password'}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions className={classes.justifyContentCenter}>
                            <Button style={style.btnLogin} type="submit"
                            >Connexion</Button>
                        </CardActions>
                    </form>
                )}
            />
            <RequestPwdForm />
        </div>
        )}
    }

export default (LoginForm)
