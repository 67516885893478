import React from 'react'
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import {toast, ToastContainer} from "react-toastify";
import {FieldGroup, FormBuilder, Validators} from "react-reactive-form";
import style from "../../../assets/css/style";
import translation from "../../../translation/translate";
import ReactiveFormInputText from "../../../shared/form/reactive-form-input-text.component";
import LockOpen from "@mui/icons-material/LockOpen";
import RequestPwdForm from "./request-password-form.component";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import {LOGIN_FORM, PROFILE_FORM} from '../../../shared/utils/variable'
import authApi from '../../actions/auth.action'

interface Props {
    classes: any;
    history: any;
    token: string;
}

class ResetPwdForm extends React.Component<Props> {

    passwordForm = FormBuilder.group({
        [LOGIN_FORM.PASSWORD]: ["", [Validators.required, Validators.pattern(translation[process.env.REACT_APP_CLIENT as string].register_validator_password)]],
        [PROFILE_FORM.CONFIRM_PWD]: ["", [Validators.required, Validators.pattern(translation[process.env.REACT_APP_CLIENT as string].register_validator_password)]],
    });

    handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        const formData = {
            password: this.passwordForm.value[LOGIN_FORM.PASSWORD],
            confirm_password: this.passwordForm.value[PROFILE_FORM.CONFIRM_PWD],
        };
        if (this.passwordForm.value[LOGIN_FORM.PASSWORD] === this.passwordForm.value[PROFILE_FORM.CONFIRM_PWD]) {
            authApi.reset_password({password: formData[LOGIN_FORM.PASSWORD], token: this.props.token})
                .then(() => {
                    toast.success("Mot de passe bien enregistré");
                    setTimeout(() => {
                        this.props.history.push('/login')
                    }, 2000);
                });
        } else {
            toast.error('Le nouveau mot de passe et la confirmation de mot de passe ne sont pas identiques')
        }
    }

    componentDidMount() {
        const token = this.props.token
        authApi.forgot_pwd_token(token)
            .then(resp => null)
            .catch(err => {
                this.props.history.push('/login');
                toast.error('Le lien que vous essayez d\'accéder est expiré ou n\'existe pas !')
            })
    }

    render() {
        const {classes} = this.props;

        return (
            <div>
                <ToastContainer/>
                <Grid container justifyContent='center' alignItems="center">
                    <Grid item md={6} lg={4} xs={10}>
                        <Card>
                            <FieldGroup
                                control={this.passwordForm}
                                render={({get, invalid}) => (
                                    <form onSubmit={this.handleSubmit}>
                                        <CardContent>
                                            <h4 style={style.cardTitle as any}>{translation[process.env.REACT_APP_CLIENT as string].login_title}</h4>
                                        </CardContent>
                                        <CardContent>
                                            <ReactiveFormInputText inputLabel={'Enter nouveau mot de passe *'}
                                                                   inputName={LOGIN_FORM.PASSWORD}
                                                                   inputIcon={<LockOpen/>}
                                                                   type={'password'}/>
                                            <ReactiveFormInputText inputLabel={'Confirmation nouveau mot de passe *'}
                                                                   inputName={PROFILE_FORM.CONFIRM_PWD}
                                                                   inputIcon={<LockOpen/>}
                                                                   type={'password'}/>
                                        </CardContent>
                                        <CardActions >
                                            <Button style={style.btnLogin} type="submit"
                                                    disabled={invalid}>Enregistrer</Button>
                                        </CardActions>
                                        <RequestPwdForm/>
                                    </form>
                                )}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default ResetPwdForm
