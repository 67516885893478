import React from 'react';
import DoneAll from '@mui/icons-material/DoneAll';
import LockOpen from '@mui/icons-material/LockOpen';
import Button from '@mui/material/Button';
import api from '../../api';
import {FieldGroup, FormBuilder, Validators} from "react-reactive-form";
import {toast} from "react-toastify";
import ReactiveFormInputText from "../../shared/form/reactive-form-input-text.component";
import style from '../../assets/css/style';
import translation from '../../translation/translate';
import {PROFILE_FORM} from "../../shared/utils/variable";
import Grid from "@mui/material/Grid";

interface Props {
    classes:any;
}

class ChangePwdForm extends React.Component <Props>{
    passwordForm = FormBuilder.group({
        [PROFILE_FORM.PLAIN_PWD]: ["",[Validators.required, Validators.pattern(translation[process.env.REACT_APP_CLIENT as string].register_validator_password)]],
        [PROFILE_FORM.CONFIRM_PWD]: ["", Validators.required, Validators.pattern(translation[process.env.REACT_APP_CLIENT as string].register_validator_password)],
        [PROFILE_FORM.CURRENT_PWD]: ["", [Validators.required, Validators.pattern(translation[process.env.REACT_APP_CLIENT as string].register_validator_password)]],
    });

    handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        api.user.change_pwd(this.passwordForm.value)
            .then(resp => {
                toast.success('Votre profil a été mis à jour');
            })
            .catch(error => {
                toast.error('Mot de passe incorrect : ' + translation[process.env.REACT_APP_CLIENT as any].register_wording_password);
            })
    };

    render() {
        const {classes} = this.props;

        return (
            <FieldGroup
                control={this.passwordForm}
                render={() => (
                    <form onSubmit={this.handleSubmit}>
                        <Grid container>
                            <Grid item xs={12} sx={{mb:'18px'}}>
                                <ReactiveFormInputText inputLabel={'Mot de passe actuel *'} inputName={PROFILE_FORM.CURRENT_PWD}
                                                       inputIcon={<LockOpen/>} type={'password'}
                                                       />
                            </Grid>
                            <Grid item xs={12} sx={{mb:'18px'}}>
                                <ReactiveFormInputText inputLabel={'Nouveau mot de passe'} inputName={PROFILE_FORM.PLAIN_PWD}
                                                       inputIcon={<LockOpen/>} type={'password'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <ReactiveFormInputText inputLabel={'Confirmation du nouveau mot de passe'} inputName={PROFILE_FORM.CONFIRM_PWD} inputIcon={<DoneAll/>}
                                                       type={'password'}/>
                            </Grid>
                        </Grid>
                        <div className={classes.center}>
                              <Button
                                 style={style.btnLogin}
                                 type='submit'>
                                 Enregistrer
                              </Button>
                        </div>
                    </form>
                )}
            />
        );
    }
}

export default ChangePwdForm
