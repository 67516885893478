import React, {Component} from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {
    AbstractControl,
    FieldArray, FieldControl, FieldGroup,
    FormArray,
    FormBuilder, Validators,
} from "react-reactive-form";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import style from '../../assets/css/style';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import mediaObjectApi from "../actions/mediaObject";
import {toast} from "react-toastify";

interface Props {
    title: string;
    label?: any,
    type?: any,
    required: boolean;
    comments?: any
}

interface State {
    name: string;
}

class UploadFormParticipationComponent extends Component <Props, State> {
    keyCount = 0;
    formArray: FormArray | undefined;

    constructor(props: Props) {
        super(props);
        this.state = {
            name: '',
        };
    }

    createUniqueKey = () => {
        return this.keyCount++;
    };

    addUpload(control: FormArray, required: boolean) {
        let formGroup;
        if (required) {
            formGroup = FormBuilder.group({
                [this.props.title]: [null, Validators.required],
            });
        } else {
            formGroup = FormBuilder.group({
                [this.props.title]: [null],
            });
        }
        formGroup.meta = {
            key: this.createUniqueKey()
        };
        control.push(formGroup);
    }

    renderControlButton(control: any, index: any) {
        return (
            <Tooltip title="Supprimer ce fichier">
                <IconButton
                    style={{color: '#f49c00'}}
                    aria-label="remove"
                    onClick={() => this.removeItemUpload(control, index)}
                    size="large">
                    <HighlightOffIcon/>
                </IconButton>
            </Tooltip>
        );
    }

    removeItemUpload(control: { controls: string | any[]; removeAt: (arg0: any) => void; }, index: any) {
        if (control.controls && control.controls.length > 0) {
            control.removeAt(index);
        }
    }

    renderFile(formControl: { value: any; touched: any; submitted: any; errors: {}; }) {
        let errorHas = null;
        const formValue = formControl.value
        if ((formControl.touched || formControl.submitted) && !!formControl.errors) {
            errorHas = Object.keys(formControl.errors);
            return errorHas;
        }

        let errorMsg = "";

        if ((formValue && formValue.name && formValue.name.split('.').pop() !== 'jpg') ||
            (formValue && formValue.name && formValue.name.split('.').pop() !== 'jpeg') ||
            (formValue && formValue.name && formValue.name.split('.').pop() !== 'png') ||
            (formValue && formValue.name && formValue.name.split('.').pop() !== 'pdf') ||
            (formValue && formValue.name && formValue.name.split('.').pop() !== 'JPG') ||
            (formValue && formValue.name && formValue.name.split('.').pop() !== 'JPEG') ||
            (formValue && formValue.name && formValue.name.split('.').pop() !== 'PNG') ||
            (formValue && formValue.name && formValue.name.split('.').pop() !== 'PDF')) {
            errorMsg = 'Le format du fichier que vous avez téléchargé ne convient pas. Vous pouvez télécharger des fichiers aux formats : jpg, png et pdf.'
        }

        if (formValue && formValue.size > '6291456') {
            errorMsg = 'La taille du fichier que vous avez téléchargé ne convient pas. 6 Mo maximum'
        } else {
            errorMsg = ""
        }
        return (
            <div>
                <input type="file" id="file" name="file" accept=".png, .jpg, .pdf, .jpeg, .JPG, .PNG, .PDF, .JPEG"
                       style={style.divUpload} onChange={this.handleFile(formControl as any) as any}/>
                {(formControl.touched || formControl.submitted) && !!formControl.errors &&
                <p style={{color: 'red', fontSize: '9px'}}>{errorMsg}</p>
                }

            </div>
        )
    }

    getDataUpload(data: { data: any; }, formControl: { meta?: { type: string | Blob; }; patchValue: any; }) {
        formControl.patchValue(data.data);
    }

    renderCommentFormGroup(control: any, title: string) {
        return control.controls.map((questionControl: AbstractControl, index: string) => {
            return (
                <Grid container key={`${questionControl.meta.key}-${String(index)}`} style={{marginTop: '15px'}}
                      data-cy={'question' + index}>
                    <FieldGroup
                        control={questionControl}
                        render={() => (
                            <Grid container justifyContent="center" spacing={5}>
                                <Grid item xs={1}>
                                    {this.renderControlButton(control, index)}
                                </Grid>
                                <Grid item xs={10}>
                                    <FieldControl
                                        name={title}
                                        meta={{type: title}}
                                        render={this.renderFile.bind(this) as any}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    />
                </Grid>
            )
        })
    }

    handleFile(formControl: { meta: { type: string | Blob; }; patchValue: (arg0: null) => void; }) {
        return (e: any) => {let file = e.target.files[0];
            let data = new FormData();
            data.append('file', file as any);
            data.append('mediaType', formControl.meta.type);
            if (file.size < '6291456') {
                if (file.name.split('.').pop() === 'jpg' ||
                    file.name.split('.').pop() === 'jpeg' ||
                    file.name.split('.').pop() === 'pdf' ||
                    file.name.split('.').pop() === 'png' ||
                    file.name.split('.').pop() === 'JPG' ||
                    file.name.split('.').pop() === 'JPEG' ||
                    file.name.split('.').pop() === 'PDF' ||
                    file.name.split('.').pop() === 'PNG') {

                    mediaObjectApi.uploadFile(data)
                        .then((data: { data: any; }) => {
                            formControl.patchValue(e.target.files[0]);
                            toast.success('Fichier téléchargé avec succès')
                            return this.getDataUpload(data, formControl);
                        })
                        .catch((error: any) => {
                            console.error(error)
                        });
                } else {
                    formControl.patchValue(null);
                    toast.error('Le format du fichier que vous avez téléchargé ne convient pas. Vous pouvez télécharger des fichiers aux formats : jpg, png et pdf.')
                }
            } else {
                formControl.patchValue(null);
                toast.error('La taille du fichier que vous avez téléchargé ne convient pas. 6 Mo maximum.')
            }
        }
    }

    renderFormUpload() {
        return (
            <FieldArray
                name={this.props.title}
                render={((control) => {
                    this.formArray = control as any;
                    return (
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                {this.renderCommentFormGroup(control, this.props.title)}
                            </Grid>
                        </Grid>
                    )
                })}
            />
        )
    }

    render() {
        let displayAddQuestion = true;
        if (this.formArray) {
            if (this.formArray.controls.length > 0) {
                displayAddQuestion = false;
            }
        }
        return (
            <Grid container style={{marginTop: '15px'}}>
                {!displayAddQuestion && <Grid item xs={12}>
                </Grid>}
                <Grid item xs={10}>
                    {this.renderFormUpload()}
                </Grid>
                <Grid item xs={2}>
                    <p>{this.state.name}</p>
                </Grid>
                <div style={{margin: 'auto'}}>
                    <Button style={style.btnUpload as any}
                            data-cy="add-first-question" startIcon={<AddCircleIcon/>} onClick={() => {
                        this.addUpload(this.formArray as any, this.props.required);
                    }}>Ajouter un fichier {this.props.required && '*'}
                    </Button>
                </div>
            </Grid>
        );
    }
}

export default UploadFormParticipationComponent;


