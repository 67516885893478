import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import withStyles from '@mui/styles/withStyles';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Hidden from '@mui/material/Hidden'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Button from '@mui/material/Button'
import pagesRoutes from '../routes/pages'
import { logout } from '../authentication/actions/auth-function.action'
import { ToastContainer } from 'react-toastify';
import headerStyle from '../assets/css/header-style.jsx'
import HeaderLink from '../layouts/header-link.component';

class PagesHeader extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      anchorEl: null
    }
  }

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open })
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    if ('/' === this.props.location.pathname && '/' === routeName) {
      return true
    } else if ('/' !== routeName)
      return this.props.location.pathname.indexOf(routeName) > -1 ? true : false
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({open: false})
    }
  }
  render() {
    let array_images = [];
    let req = require.context(`../assets/img/${process.env.REACT_APP_CLIENT}/logo/`, false, /\.png$/)
    req.keys().forEach(function(key) {
      req(key);
      let name_image = key.slice(2)
      array_images.push({photos:require(`../assets/img/${process.env.REACT_APP_CLIENT}/logo/${name_image}`)})
    });
    const { classes, isAuthenticated, logout } = this.props;

    let list = (
        <List className={classes.list}>
          {pagesRoutes.map((prop, key) => {
            if (prop.noHeader || prop.redirect || (!isAuthenticated && prop.requireAuth) || (isAuthenticated && false === prop.requireAuth)) {
              return null
            }

            return (
                <HeaderLink key={key} path={prop.path} classes={classes} label={prop.name} />
            )
          })}
          {isAuthenticated &&
          <ListItem className={classes.listItem}>
            <Button onClick={() => logout()}>

              <ListItemText
                  primary='Déconnexion'
                  disableTypography={true}
                  className={classes.listItemText}
              />
            </Button>
          </ListItem>}
        </List>
    )

    return (
      <AppBar position='static' className={classes.appBar }>
        <ToastContainer/>
        <Toolbar className={classes.container}>
          <Hidden mdDown implementation='css'>
            <div className={classes.flex}>
              {array_images.map((e, idx) =>
                  <Button key={idx} component={NavLink} to='/' className={classes.title}>
                    <img src={e.photos.default} alt="Logo" className={classes.logo} />
                  </Button>
              )}
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.flex}>
              {array_images.map((e, idx) =>
                  <Button key={idx} component={NavLink} to='/' className={classes.title} >
                    <img src={e.photos.default} alt="Logo" className={classes.logo} />
                  </Button>
              )}
            </div>
          </Hidden>
          <Hidden mdDown implementation='css'>
            {list}
          </Hidden>
          <Hidden mdUp>
            {isAuthenticated &&
            <Button
                className={classes.sidebarButton}
                justIcon
                aria-label='open drawer'
                onClick={this.handleDrawerToggle}
            >
              <MenuIcon/>
            </Button>
            }
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.auth.token
  }
}

export default connect(mapStateToProps, { logout })(withStyles(headerStyle)(PagesHeader))
