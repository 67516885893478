import React from 'react'
import ReactTable from 'react-table'
import {Link} from 'react-router-dom'
import RemoveRedEye from '@mui/icons-material/RemoveRedEye'
import InfoIcon from '@mui/icons-material/Info';
import Edit from '@mui/icons-material/Edit'
import PictureAsPdf from '@mui/icons-material/PictureAsPdf'
import Button from '@mui/material/Button';
import style from '../../assets/css/style.jsx';
import api from '../../api'
import {toLocaleString} from '../../shared/utils/utils'
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Popover from "@mui/material/Popover";
import ImageIcon from "@mui/icons-material/Image";
import Grid from '@mui/material/Grid';

const columnImage = (row) => {
    const img = row.value.image && row.value.image.contentUrl;
    const imgPath = process.env.REACT_APP_BACKEND_URL + '/media/' + img;
    return (
      <img src={imgPath} alt='' style={{width:'120px'}}/>
    );
};


const tableConf = {
    previousText: 'Précédent',
    nextText: 'Suivant',
    loadingText: 'Chargement...',
    noDataText: 'Lignes non trouvées',
    pageText: 'Page',
    ofText: 'of',
    rowsText: 'lignes',
    sizeOptions: [10, 25, 50],
    defaultSize: 10,
    columns: [
        {
            Header: '',
            accessor: 'operation',
            filterable: false,
            Cell: columnImage,
            sortable: false
        },
        {
            Header: 'Numéro',
            accessor: 'code',
            filterable: false,
        },
        {
            Header: 'Opération',
            accessor: 'operation.title',
            filterable: false
        },
        {
            Header: 'Statut',
            accessor: 'state.status',
            filterable: false
        },
        {
            Header: 'Date de virement',
            accessor: 'financialDate',
            filterable: false
        },
        {
            Header: 'Date',
            accessor: 'createdAt',
            filterable: false
        },
        {
            Header: 'Fichier',
            accessor: 'file',
            sortable: false,
            filterable: false
        }
    ]
};

const stateButton = ['#f8971d', '#4caf50', '#75d1e0', '#f8971d', '#db190b', '#4caf50', '#f8971d']

export default class ConsumerRequestsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            openRecap: false,
            idPart: '',
        };
        tableConf.columns[6].Cell = this.columnFile.bind(this);
    }

    handleClickRecap = (original) => {
        this.setState({openRecap: true});
        this.setState({idPart: original});
    };
    handleClosePopover = () => {
        this.setState({anchorEl:null,openedPopoverId:false})
    };

    handleClickPopover = (ticketId) => event => {
        this.setState({anchorEl:event.currentTarget, openedPopoverId: ticketId})
    };

    handleClose = () => {
        this.setState({openRecap: false});
    };

    fetchData = (state) => {
        const {classes} = this.props;
        const params = {
            page: state.page + 1,
            pageSize: state.pageSize,
            order: state.sorted.reduce((accumulator, currentValue) => {
                accumulator[currentValue.id] = currentValue.desc ? 'desc' : 'asc';
                return accumulator
            }, {}),
            filtered: state.filtered
        };

        this.setState({loading: true});

        api.operations.my_participations(params)
          .then((res) => {
              this.setState({
                  data: res.data['hydra:member'].map((prop) => {
                      return {
                          ...prop,
                          createdAt: toLocaleString(prop.createdAt),
                          financialDate: toLocaleString(prop.financialDate),
                          state: {
                              ...prop.state,
                              status: (
                                <Button
                                  className={classes.actionButton + '' + classes.fakeButton}
                                  size='small'
                                  style={{backgroundColor:stateButton[prop.state.id],color:'white',fontSize: '10px'}}>
                                    {prop.state.stateLabel && prop.state.stateLabel !== null ? prop.state.stateLabel : prop.state.status}
                                </Button>
                              )
                          }
                      };
                  }),
                  pages: Math.ceil(res.data['hydra:totalItems'] / state.pageSize),
                  loading: false
              })
          })
          .catch(error => {
              this.setState({
                  data: [],
                  loading: false
              })
          })
    }

    columnFile({original}) {
        const {classes} = this.props;

        return (
          <Grid container justifyContent='center' >
              {original.ticket !== null &&
                  <p onClick={() => window.open(`${process.env.REACT_APP_BACKEND_URL}/media/${original.ticket.contentUrl}`, '_blank')}
                     style={style.btnEye}>
                      <RemoveRedEye/>
                  </p>
              }
              {original.tickets.length > 0 &&
                  <div>
                      <p style={style.btnEye}
                         onClick={this.handleClickPopover(original.id)}>
                          <RemoveRedEye/>
                      </p>
                      <Popover
                          key={original.id}
                          id={original.id}
                          elevation={1}
                          open={this.state.openedPopoverId === original.id }
                          anchorEl={this.state.anchorEl}
                          onClose={this.handleClosePopover}
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                          }}
                          transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                          }}
                      >
                          {original.tickets.map((tickets, idx) =>(
                              <p
                                  onClick={() => window.open(`${process.env.REACT_APP_BACKEND_URL}/media/${tickets.contentUrl}`, '_blank')}
                                  className={classes.btnEye}
                              >
                                  <ImageIcon/>
                              </p>))}


                      </Popover>
                  </div>
              }
            <p   onClick={event => this.handleClickRecap(original)} style={{color: "#1b9760" ,float:'right', cursor:'pointer',marginLeft:'5px'}} >
                <Tooltip title="Recapitulatif de ma participation">
                    <InfoIcon/>
                </Tooltip>
            </p>
              {original.state.id === 5 &&
                  <Tooltip title="Mon PDF"
                           onClick={() => api.participation.get_refund_proof(original.id)}
                           style={{color:"#00a950",cursor:'pointer'}}
                           target="_blank"
                           color='success'
                  >
                      <PictureAsPdf/>
                  </Tooltip>
              }
              {original.state.id === 4 && new Date(original.operation.closingDate) > new Date() &&
              <Link to={{pathname:`/modifier-demande/${original.operation.id}/part/${original.id}`, state:{data:original.operation,edited:true,type:original.operation['@type']}}}>

                  <p
                    style={{color:"#75d1e0"}}
                    className={classes.actionButton + ' ' + classes.actionButtonRound}
                  >
                    <Tooltip title="Modifier ma demande">
                      <Edit/>
                    </Tooltip>
                  </p>
              </Link>}
          </Grid>

        )
    };

    render() {
        const {page, data, pages, loading} = this.state
        let dataFilter;
        if (data[0]) {
            dataFilter = _.filter(data, function (o) {
                return o.state.id !== 6
            })
        }
        let statusDefault = null;
        switch (this.state.idPart.state && this.state.idPart.state.id) {
            case 1:
                statusDefault = "EN COURS";
                break;
            case 2:
                statusDefault = "CONFORME";
                break;
            case 3:
                statusDefault = "NON CONFORME (NC)";
                break;
            case 4:
                statusDefault = "NON CONFORME (NCT)";
                break;
            case 5:
                statusDefault = "REMBOURSEMENT VALIDE";
                break;
            case 6:
                statusDefault = "PARTICIPATION MODIFIEE";
                break;
            default:
        }

        return (
            <div >
                <Dialog open={this.state.openRecap} onClose={this.handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">Récapitulatif de ma participation</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <List >
                                <ListItem>
                                    <ListItemText primary="Numéro de participation" secondary={this.state.idPart.code} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Opération" secondary={this.state.idPart.operation && this.state.idPart.operation.code + '-' + this.state.idPart.operation.title} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Date de participation" secondary={this.state.idPart.createdAt} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Motif de non conformité" secondary={this.state.idPart.reasonNotConformity && this.state.idPart.reasonNotConformity.length !== 0 ? this.state.idPart.reasonNotConformity[0].libelle_long : <Chip label="Aucun" />} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Statut" secondary={statusDefault} />
                                    <ListItemText primary="IBAN" secondary={this.state.idPart.customer && this.state.idPart.customer.cachedIban} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Montant" secondary={this.state.idPart.financialAmount  ? this.state.idPart.financialAmount +'€' : <Chip label="Aucun" />} />
                                    <ListItemText primary="Date de virement" secondary={this.state.idPart.financialDate  ? this.state.idPart.financialDate : <Chip label="Aucun" />} />
                                </ListItem>
                            </List>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="warning">Fermer</Button>
                    </DialogActions>
                </Dialog>
          <ReactTable
            page={page}
            data={dataFilter}
            pages={pages}
            previousText='Précédent'
            nextText='Suivant'
            loadingText='Chargement...'
            noDataText='Lignes non trouvées'
            pageText='Page'
            rowsText='lignes'
            manual
            loading={loading}
            pageSizeOptions={tableConf.sizeOptions}
            filterable
            columns={tableConf.columns}
            defaultPageSize={tableConf.defaultSize}
            showPaginationBottom
            className='-striped -highlight'
            onFetchData={(state, instance) => this.fetchData(state, instance)}
          />
            </div>
        )
    }
}

