import React from 'react'
import LocationOn from '@mui/icons-material/LocationOn'
import Fingerprint from '@mui/icons-material/Fingerprint'
import Email from '@mui/icons-material/Email'
import style from '../../../assets/css/style.jsx';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {FieldGroup, FormBuilder, Validators} from "react-reactive-form";
import CardContent from "@mui/material/CardContent";
import ReactiveFormInputText from "../../../shared/form/reactive-form-input-text.component";
import CardActions from "@mui/material/CardActions";
import translation from '../../../translation/translate';
import {toast} from "react-toastify";
import {REGISTER_FORM, PROFILE_FORM} from '../../../shared/utils/variable'
import authApi from '../../actions/auth.action'
import Grid from "@mui/material/Grid";

interface Props {
    classes:any;
    history:any;
}

interface State {
    open:boolean;
}

class RegisterForm extends React.Component <Props, State> {
    constructor(props:Props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    registerForm = FormBuilder.group({
        [REGISTER_FORM.COMPANY_ID]: ["", Validators.required],
        [PROFILE_FORM.EMAIL]: ["", Validators.required],
        [PROFILE_FORM.POSTAL_CODE]: ["", Validators.required],
        [PROFILE_FORM.PLAIN_PWD]: ["", [Validators.required,Validators.pattern(translation[process.env.REACT_APP_CLIENT as string].register_validator_password)]],
        [PROFILE_FORM.CONFIRM_PWD]: ["", [Validators.required,Validators.pattern(translation[process.env.REACT_APP_CLIENT as string].register_validator_password)]],
    });

    handleClick() {
        this.setState({open: true});
    }

    handleClose() {
        this.setState({open: false});
    }

    handleSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        authApi.register(this.registerForm.value)
                .then(resp => {
                    toast.success('Votre compte a bien été créé.')
                    this.props.history.push('/')
                })
                .catch(error => {
                    toast.error(error.response.data['hydra:description'])
                })
    };

    render() {
        const {classes} = this.props;

        return (
            <div>
            <FieldGroup
                control={this.registerForm}
                render={() => (
                    <form onSubmit={this.handleSubmit}>
                        <CardContent>
                            <h4 style={style.cardTitle as any}>Formulaire d'inscription</h4>
                        </CardContent>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12} sx={{mb:'18px'}}>
                                    <ReactiveFormInputText inputLabel={translation[process.env.REACT_APP_CLIENT as string].label_cip_register } inputName={REGISTER_FORM.COMPANY_ID}
                                                           inputIcon={<Fingerprint/>} type={'text'}/>
                                </Grid>
                                <Grid item xs={12} sx={{mb:'18px'}}>
                                    <ReactiveFormInputText inputLabel={'Code postal *'} inputName={PROFILE_FORM.POSTAL_CODE}
                                                           inputIcon={<LocationOn/>} type={'text'}/>
                                </Grid>
                                <Grid item xs={12} sx={{mb:'18px'}}>
                                    <ReactiveFormInputText inputLabel={'Email *'} inputName={PROFILE_FORM.EMAIL} inputIcon={<Email/>}
                                                           type={'email'}/>
                                </Grid>
                                <Grid item xs={12} sx={{mb:'18px'}}>
                                    <ReactiveFormInputText inputLabel={'Mot de passe *'} inputName={PROFILE_FORM.PLAIN_PWD}
                                                           inputIcon={<LocationOn/>} type={'password'}/>
                                </Grid>
                                <Grid item xs={12} >
                                    <ReactiveFormInputText inputLabel={'Confirmation mot de passe *'}
                                                   inputName={PROFILE_FORM.CONFIRM_PWD} inputIcon={<LocationOn/>}
                                                   type={'password'}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions className={classes.justifyContentCenter}>
                            <Button style={style.btnLogin} type="submit"
                            >Enregistrer</Button>
                        </CardActions>

                    </form>
                )}
            />
                <Button style={style.btnPswForget} variant="outlined" onClick={this.handleClick.bind(this)}>
                    Comment ça marche ?
                </Button>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Comment ça marche ?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p className={classes.modalText}>
                                Pour participer à une opération, il vous suffit de :
                            </p>
                            <ol className={classes.modalListWrapper}>
                                <li className={classes.modalListItem}><span className={classes.modalListItemBold}>Télécharger</span> nos mandats</li>
                                <li className={classes.modalListItem}><span className={classes.modalListItemBold}>Déclarer</span> vos remboursements</li>
                                <li className={classes.modalListItem}><span className={classes.modalListItemBold}>Consulter</span> le Statut de votre dossier et vos remboursements</li>
                                <li className={classes.modalListItem}><span className={classes.modalListItemBold}>Conserver</span> vos données en toute confidentialité</li>
                            </ol>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default RegisterForm
